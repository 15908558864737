.editorClassName {
  min-height: 250px;
}
.custom-link-popup {
  background: 'red';
  height: 250px !important;
}

.rdw-fontfamily-placeholder {
  font-size: 12px;
}

.rdw-dropdown-selectedtext {
  font-size: 12px;
}

h1 {
  font-family: 'League Spartan' !important;
  font-size: 32px !important;
  font-weight: bold !important;
}

h2 {
  font-family: 'League Spartan' !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

h3 {
  font-family: 'League Spartan' !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

h4 {
  font-family: 'League Spartan' !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

h5 {
  font-family: 'League Spartan' !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

h6 {
  font-family: 'League Spartan' !important;
  font-size: 10px !important;
  font-weight: bold !important;
}

.display ul {
  list-style: inside !important;
  display: block !important;
  list-style-type: disc !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 40px !important;
  unicode-bidi: isolate !important;
}

.display ol {
  list-style: inside !important;
  display: block !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 40px !important;
  unicode-bidi: isolate !important;
  list-style-type: decimal !important;
}

.display a {
  text-decoration: underline;
  color: blue;
}
